import { EthereumContext } from "../eth/context";
import { createProvider } from "../eth/provider";
// import { createInstance } from '../eth/registry';
import { createInstance } from "../eth/nft";

import "./App.css";
// import Registrations from "./Registrations";
// import Register from "./Register";
import Claim from "./minting";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const provider = createProvider();
  const nft = createInstance(provider);
  const ethereumContext = { provider, nft };

  return (
    <div className="App">
      <header
        className="App-header"
        style={{ backgroundImage: "url(/background.png)" }}
      >
        <section className="wrap">
          <h1>10周年記念</h1>
          <h2>NFT</h2>
          <p>powered by LASHISA</p>
        </section>
      </header>
      <section className="App-content">
        <EthereumContext.Provider value={ethereumContext}>
          <Claim />
          {/* <Registrations /> */}
        </EthereumContext.Provider>
      </section>
      <ToastContainer hideProgressBar={true} />
    </div>
  );
}

export default App;
