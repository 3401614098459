import { useState, useContext } from "react";
import { addChain, claim } from "../eth/mint";
import { EthereumContext } from "../eth/context";
import { toast } from "react-toastify";
import "./Register.css";

function Claim() {
  const [submitting, setSubmitting] = useState(false);
  const { nft, provider } = useContext(EthereumContext);

  const add = async (event) => {
    event.preventDefault();

    try {
      const response = await addChain();
    } catch (err) {
      toast(err.message || err, { type: "error" });
    } finally {
    }
  };
  const sendTx = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    try {
      const response = await claim(nft, provider);
      const hash = response.hash;
      const onClick = hash
        ? () => window.open(`https://polygonscan.com/tx/${hash}`)
        : undefined;
      toast("Transaction sent!", { type: "info", onClick });
    } catch (err) {
      toast(err.message || err, { type: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="Container">
      <form onSubmit={add}>
        <button type="submit">add chain</button>
      </form>
      <h3>
        <form onSubmit={sendTx}>
          <button type="submit" disabled={submitting}>
            {submitting ? "Minting..." : "Mint"}
          </button>
        </form>
      </h3>
      <h3>
        <a href="https://opensea.io/account/private">
          {/* rome-ignore lint/a11y/useButtonType: <explanation> */}
          <button>opensea</button>
        </a>
      </h3>
    </div>
  );
}

export default Claim;
