import { ethers } from "ethers";
import { createInstance } from "./forwarder";
import { signMetaTxRequest } from "./signer";

async function sendTx(nft) {
  console.log("claim");
  return nft.claim();
}

async function sendMetaTx(nft, provider, signer) {
  const url = process.env.REACT_APP_WEBHOOK_URL;
  if (!url) throw new Error(`Missing relayer url`);

  const forwarder = createInstance(provider);
  const from = await signer.getAddress();
  const data = nft.interface.encodeFunctionData("claim", [from]);
  console.log(data);
  const to = nft.address;

  const request = await signMetaTxRequest(signer.provider, forwarder, {
    to,
    from,
    data,
  });
  //sleep random time between 1 and 10 seconds
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  await sleep(Math.floor(Math.random() * 10000) + 1000);
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(request),
    headers: { "Content-Type": "application/json" },
  });
}

export async function addChain() {
  if (!window.ethereum) throw new Error(`User wallet not found`);

  await window.ethereum.enable();
  const userProvider = new ethers.providers.Web3Provider(window.ethereum);
  const userNetwork = await userProvider.getNetwork();
  if (userNetwork.chainId !== 137) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      });
    } catch (error) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainName: "Polygon Mainnet",
            chainId: "0x89",
            nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
            rpcUrls: ["https://polygon-rpc.com/"],
          },
        ],
      });
    }
  }
}
export async function claim(nft, provider) {
  if (!window.ethereum) throw new Error(`User wallet not found`);

  await window.ethereum.enable();
  const userProvider = new ethers.providers.Web3Provider(window.ethereum);
  const userNetwork = await userProvider.getNetwork();
  if (userNetwork.chainId !== 137) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      });
    } catch (error) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainName: "Polygon Mainnet",
            chainId: "0x89",
            nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
            rpcUrls: ["https://polygon-rpc.com/"],
          },
        ],
      });
    }
  }
  //check users nft balance
  if ((await nft.balanceOf(await userProvider.getSigner().getAddress())) > 0) {
    throw new Error("You already have a NFT");
  } else {
    const signer = userProvider.getSigner();
    return sendMetaTx(nft, provider, signer);
  }
}
